import React, { useEffect, useState } from 'react';
import Header from './component/header';
import SideMenu2 from './component/sideMenu2';
import { useNavigate } from 'react-router-dom';
import home from './images/HOME.png';
import circle from './images/circle.png';
import HeaderIdeation from './component/headerIdeation';
import { handleClick, handleClickStorage, handleHome, handleLogout, updateStreak, getUserIdFromToken, FetchProjectDetails, FetchGoStatus, FetchTimelines, FetchTimelinesCount, FetchUser } from "./utils/startUtils";


function IdeationMain() {
    const navigate = useNavigate()
    const [showScrollableDiv, setShowScrollableDiv] = useState(false);
    return (
        <div
            style={{
                fontFamily: '"Manrope", sans- serif'
            }}
            className='container2'>
            <SideMenu2 />
            <div className="main-content">
                <HeaderIdeation />

                <div className='container relative'>

                    <div className="absolute inset-0 mt-[80px] ml-[60px]  z-[-100] bg-no-repeat bg-cover w-[200px] h-[200px] " style={{ backgroundImage: `url(${circle})` }}></div>
                    <div className="main-content2">
                        <div className=' w-fit ml-auto ' >
                            <div>
                                <img src={home} />

                            </div>
                        </div>
                        <div className='bacWHI mt-[20px]'>
                            <div className=' m-auto' >
                                <p className='text-center font-bold text-[17px] ' > Phase progress</p>
                                <p className='text-center text-[#8A8A8A] font-semibold text-[10px] ' >Here you see your progress and how far you've gone</p>
                            </div>
                            <div className='flex w-full lg:w-[50%] m-auto'>
                                <div>
                                    <p className='text-center font-bold text-[15px]'>Business Case Builder</p>
                                    <div className='bg-[#0B1D50] p-[15px] px-[40px] text-white rounded-md text-center'>
                                        <div className='flex justify-center items-center'>
                                            {/* Half Donut SVG */}
                                            <svg width="100" height="60" viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M 10,50 A 40,40 0 1,1 90,50"
                                                    fill="none"
                                                    stroke="#e0e0e0"
                                                    strokeWidth="10"
                                                    strokeLinecap="round"
                                                />
                                                <path
                                                    d="M 10,50 A 40,40 0 1,1 90,50"
                                                    fill="none"
                                                    stroke="#1B45BF"
                                                    strokeWidth="10"
                                                    strokeDasharray="126"
                                                    strokeDashoffset="85"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </div>
                                        <p className='mt-[-30px]'>32%</p>
                                        <p className='text-[14px]'>progress</p>
                                        <button onClick={() =>
                                        handleClickStorage(
                                            'BusinessCaseBuilder',
                                            '/questionBusMain/Ideation/BusinessCaseBuilder/Introduction'
                                        )} className='m-auto bg-[#1B45BF] px-2 py-1 rounded-lg text-white text-[14px]'>Continue</button>
                                    </div>
                                </div>

                                <div className='ml-auto' >
                                    <p className='text-center font-bold text-[15px]'>Custom Finacial Projection</p>
                                    <div className='bg-[#0B1D50] p-[15px] px-[40px] text-white rounded-md text-center'>
                                        <div className='flex justify-center items-center'>
                                            {/* Half Donut SVG */}
                                            <svg width="100" height="60" viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M 10,50 A 40,40 0 1,1 90,50"
                                                    fill="none"
                                                    stroke="#e0e0e0"
                                                    strokeWidth="10"
                                                    strokeLinecap="round"
                                                />
                                                <path
                                                    d="M 10,50 A 40,40 0 1,1 90,50"
                                                    fill="none"
                                                    stroke="#1B45BF"
                                                    strokeWidth="10"
                                                    strokeDasharray="126"
                                                    strokeDashoffset="85"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </div>
                                        <p className='mt-[-30px]'>32%</p>
                                        <p className='text-[14px]'>progress</p>
                                        <button className='m-auto bg-[#1B45BF] px-2 py-1 rounded-lg text-white text-[14px]'>Continue</button>
                                    </div>
                                </div>

                                <div></div>
                            </div>

                            <div className='flex justify-center mt-[20px]' >
                                   <div>
                                    <p className='text-center font-bold text-[15px]'>Go no Go</p>


                                    <div className='bg-[#0B1D50] p-[15px] px-[40px] text-white rounded-md text-center'>
                                        <div className='flex justify-center items-center'>
                                            {/* Half Donut SVG */}
                                            <svg width="100" height="60" viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M 10,50 A 40,40 0 1,1 90,50"
                                                    fill="none"
                                                    stroke="#e0e0e0"
                                                    strokeWidth="10"
                                                    strokeLinecap="round"
                                                />
                                                <path
                                                    d="M 10,50 A 40,40 0 1,1 90,50"
                                                    fill="none"
                                                    stroke="#1B45BF"
                                                    strokeWidth="10"
                                                    strokeDasharray="126"
                                                    strokeDashoffset="85"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </div>
                                        <p className='mt-[-30px]' >32%</p>
                                        <p className='text-[14px]' >progress</p>
                                        <button className='m-auto bg-[#1B45BF] px-2 py-1 rounded-lg text-white text-[14px] '>Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default IdeationMain